<template>
  <b-modal :id="id" centered size="xl" title="New Zone" no-close-on-backdrop footer-class="d-block" @hidden="clear()">
    <!-- Content -->
    <template #default>
      <b-container>

        <validation-observer ref="observer" v-slot="{ handleSubmit }" tag="div">
          <form ref="form" @submit.stop.prevent="handleSubmit(create)">
            <b-row>
              <b-col>
                <validation-provider v-slot="validationContext" vid="name" name="Name" rules="required">
                  <b-form-group label="Name" label-class="form-control-label" :invalid-feedback="validationContext.errors[0]">
                    <b-form-input id="name-input" v-model="zone.name" :state="getValidationState(validationContext)"></b-form-input>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>

            <b-card no-body class="border shadow-sm">
              <b-card-header class="d-block">
                <b-row>
                  <b-col>
                    <label class="mb-0">
                      Counties
                    </label>
                  </b-col>
                  <b-col cols="auto">
                    <b-button size="sm" variant="light" @click="zone.counties.push('')">
                      <feather-icon icon="PlusIcon"/>
                    </b-button>
                  </b-col>
                </b-row>
              </b-card-header>
              <b-card-body>
                <b-row v-for="(county, index) in zone.counties" :id="county" :key="index" ref="row">
                  <b-col align-self="center">
                    <validation-provider v-slot="validationContext" :vid="`county-${index}`" name="County" rules="required">
                      <b-form-group label="Name" label-for="county-input" :invalid-feedback="validationContext.errors[0]">
                        <b-form-input id="county-input" v-model="zone.counties[index]" :state="getValidationState(validationContext)"></b-form-input>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col cols="auto" align-self="center">
                    <b-button size="sm" variant="light" @click="zone.counties.splice(index, 1)">
                      <feather-icon icon="TrashIcon"/></b-button>
                  </b-col>
                </b-row>
              </b-card-body>
            </b-card>
          </form>
        </validation-observer>
      </b-container>
    </template>

    <!-- Footer -->
    <template #modal-footer>
      <b-row>
        <b-col align-self="center">
          <b-checkbox v-model="state.redirect" :checked="true" :unchecked-value="false">
            <small>Redirect to page on submit</small>
          </b-checkbox>
        </b-col>
        <b-col align-self="center" class="text-right">
          <b-button type="reset" class="mr-1" @click="clear()">Clear</b-button>
          <b-button type="submit" variant="primary" @click="$refs.observer.handleSubmit(create)">Submit</b-button>
        </b-col>
      </b-row>
    </template>
  </b-modal>
</template>

<script>
  import { API, graphqlOperation } from 'aws-amplify';
  import { uuid } from 'vue-uuid';
  import vSelect from 'vue-select'
  import notify from '@/mixins/notify.mixin'
  import {createZone} from '@/graphql/mutations';

  export default {
    name: 'DistrictModal',
    components: {
      vSelect
    },
    mixins: [ notify ],
    props: {
      id: {
        type: String,
        required: true,
      },
    },
    data() {
      return {
        zone: {
          name: null,
          counties: [],
        },
        state: {
          redirect: false
        },
      }
    },
    methods: {
      async create() {
        try {
          const id = uuid.v4();
          const zoneInput = {
            id: id,
            name: this.zone.name,
            counties: this.zone.counties,
            state: {
              enabled: true
            }
          }

          const response = await API.graphql(graphqlOperation(createZone, { input: zoneInput }));
          const zone = response.data.createZone

          if(this.state.redirect) {
            await this.$router.push({name: 'management-zone', params: { id: id }})
          }
          else {
            this.$emit('created', zone)
            this.$root.$emit('bv::toggle::modal', this.id)
            this.notify({ title: 'Success', text: 'Zone was successfully created', icon: 'fas fa-server', variant: 'success' });
          }
        }
        catch (err) {
          console.error(err)
          this.notify({ title: 'Error', text: 'Zone failed to create', icon: 'fas fa-server', variant: 'danger'});
        }
      },
      clear() {
        this.zone = {
          name: null,
          counties: [],
        }
      },
      getValidationState({ dirty, validated, valid = null }) {
        return dirty || validated ? valid : null;
      },
    }
  }
</script>

<style scoped>

</style>
