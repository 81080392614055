/* eslint-disable no-await-in-loop */
import {API, graphqlOperation} from 'aws-amplify';
import {batchProcessWithRetry, SwalData} from '@/graphql/cascade/batch';

export const cascadeConfirmDeleteOptions = {
    confirm: {
        text: `
          <p class='mb-0'>Deleting this Zone may have unwanted side effects.</p>
          <p class='font-small-3 text-danger'>
            Districts, Schools and Festivals associated with this zone will have their association removed.<br/>
            This may effect applications, scoring, selections, acceptance, as well as reports.
          </p>
          <strong>Be very careful when performing this action.</strong>
        `,
        shouldParse: true
    },
    confirmed: { text: 'Zone was successfully deleted.' }
}

export async function cascadeDeleteZone(zoneId, swalCallback) {
    try {
        /** Update District records associated to this Zone **/
        const districts = await listDistricts(zoneId, swalCallback)
        await batchProcessWithRetry(districts, async (district) => {
            await API.graphql(graphqlOperation(updateDistrictMutation, { input: { id: district.id, districtZoneId: null }}));
        }, { title: 'Updating Districts', callback: swalCallback })

        /** Update School records associated to this Zone **/
        const schools = await listSchools(zoneId, swalCallback)
        await batchProcessWithRetry(schools, async (school) => {
            await API.graphql(graphqlOperation(updateSchoolMutation, { input: { id: school.id, schoolZoneId: null }}));
        }, { title: 'Updating Schools', callback: swalCallback })

        /** Update Festival records associated to this Zone **/
        const festivals = await listFestivals(zoneId, swalCallback)
        await batchProcessWithRetry(festivals, async (festival) => {
            await API.graphql(graphqlOperation(updateFestivalMutation, { input: { id: festival.id, festivalZoneId: null }}));
        }, { title: 'Updating Festivals', callback: swalCallback })

        /** Delete Zone **/
        await deleteZone(zoneId, swalCallback)
    }
    catch(e) {
        console.error(e)
        throw e
    }
}

/** Helper Functions **/
async function deleteZone(zoneId, swalCallback) {
    const title = 'Deleting Zone'
    swalCallback(new SwalData(title, 0, 1))
    await new Promise(resolve => setTimeout(resolve, 500));
    await API.graphql(graphqlOperation(deleteZoneMutation, { input: { id: zoneId }}));
    swalCallback(new SwalData(title, 1, 1))
    await new Promise(resolve => setTimeout(resolve, 500));
}

async function listDistricts(zoneId, swalCallback) {
    const items = []
    let nextToken = null;
    do {
        try {
            const input = { limit: 250, nextToken: nextToken, filter: { districtZoneId: { eq: zoneId } } }
            const response = await API.graphql(graphqlOperation(listDistrictsQuery, input));
            items.push(...response.data.listDistricts.items)
            nextToken = response.data.listDistricts.nextToken;
            swalCallback(new SwalData('Loading Districts', items.length, items.length))
        }
        catch (error) {
            console.error(error);
            break;
        }
    }
    while (nextToken);
    return items
}

async function listSchools(zoneId, swalCallback) {
    const items = []
    let nextToken = null;
    do {
        try {
            const input = { limit: 250, nextToken: nextToken, filter: { schoolZoneId: { eq: zoneId } } }
            const response = await API.graphql(graphqlOperation(listSchoolsQuery, input));
            items.push(...response.data.listSchools.items)
            nextToken = response.data.listSchools.nextToken;
            swalCallback(new SwalData('Loading Schools', items.length, items.length))
        }
        catch (error) {
            console.error(error);
            break;
        }
    }
    while (nextToken);
    return items
}

async function listFestivals(zoneId, swalCallback) {
    const items = []
    let nextToken = null;
    do {
        try {
            const input = { limit: 250, nextToken: nextToken, filter: { festivalZoneId: { eq: zoneId } } }
            const response = await API.graphql(graphqlOperation(listFestivalsQuery, input));
            items.push(...response.data.listFestivals.items)
            nextToken = response.data.listFestivals.nextToken;
            swalCallback(new SwalData('Loading Festivals', items.length, items.length))
        }
        catch (error) {
            console.error(error);
            break;
        }
    }
    while (nextToken);
    return items
}

/** Queries & Mutations **/
//districts
const listDistrictsQuery = /* GraphQL */ `
    query ListDistricts(
        $id: ID
        $filter: ModelDistrictFilterInput
        $limit: Int
        $nextToken: String
        $sortDirection: ModelSortDirection
    ) {
        listDistricts(
            id: $id
            filter: $filter
            limit: $limit
            nextToken: $nextToken
            sortDirection: $sortDirection
        ) {
            items {
                id
                districtZoneId
            }
            nextToken
        }
    }
`;

const updateDistrictMutation = /* GraphQL */ `
    mutation UpdateDistrict(
        $input: UpdateDistrictInput!
        $condition: ModelDistrictConditionInput
    ) {
        updateDistrict(input: $input, condition: $condition) {
            id
            districtZoneId
        }
    }
`;

//schools
const listSchoolsQuery = /* GraphQL */ `
    query ListSchools(
        $id: ID
        $filter: ModelSchoolFilterInput
        $limit: Int
        $nextToken: String
        $sortDirection: ModelSortDirection
    ) {
        listSchools(
            id: $id
            filter: $filter
            limit: $limit
            nextToken: $nextToken
            sortDirection: $sortDirection
        ) {
            items {
                id
                schoolZoneId
            }
            nextToken
        }
    }
`;

const updateSchoolMutation = /* GraphQL */ `
    mutation UpdateSchool(
        $input: UpdateSchoolInput!
        $condition: ModelSchoolConditionInput
    ) {
        updateSchool(input: $input, condition: $condition) {
            id
            schoolZoneId
        }
    }
`;

//festivals
const listFestivalsQuery = /* GraphQL */ `
    query ListFestivals(
        $id: ID
        $filter: ModelFestivalFilterInput
        $limit: Int
        $nextToken: String
        $sortDirection: ModelSortDirection
    ) {
        listFestivals(
            id: $id
            filter: $filter
            limit: $limit
            nextToken: $nextToken
            sortDirection: $sortDirection
        ) {
            items {
                id
                festivalZoneId
            }
            nextToken
        }
    }
`;

const updateFestivalMutation = /* GraphQL */ `
    mutation UpdateFestival(
        $input: UpdateFestivalInput!
        $condition: ModelFestivalConditionInput
    ) {
        updateFestival(input: $input, condition: $condition) {
            id
            festivalZoneId
        }
    }
`;

const deleteZoneMutation = /* GraphQL */ `
    mutation DeleteZone(
        $input: DeleteZoneInput!
        $condition: ModelZoneConditionInput
    ) {
        deleteZone(input: $input, condition: $condition) {
            id
        }
    }
`;
