var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('page-layout',{ref:"layout",scopedSlots:_vm._u([{key:"actions",fn:function(ref){return [_c('can',{attrs:{"do":"create","on":"management-zones"}},[_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip",value:('Create'),expression:"'Create'"},{name:"b-modal",rawName:"v-b-modal",value:('zone-modal'),expression:"'zone-modal'"}],attrs:{"variant":"transparent","size":"sm"}},[_c('font-awesome-icon',{attrs:{"icon":"fa-solid fa-plus"}})],1),_c('zone-modal',{attrs:{"id":"zone-modal"},on:{"created":_vm.addZone}})],1)]}},{key:"dropdown-options",fn:function(ref){return [_c('b-dropdown-item',{on:{"click":_vm.refresh}},[_c('feather-icon',{attrs:{"icon":"RotateCwIcon"}}),_c('span',{staticClass:"align-middle ml-50"},[_vm._v("Refresh")])],1)]}},{key:"content",fn:function(ref){
var state = ref.state;
return [_c('table-layout',{ref:"table-layout",attrs:{"items":_vm.table.items,"fields":_vm.table.fields,"sorting":_vm.table.sorting,"loading":_vm.table.loading,"subscriptions":_vm.table.subscriptions,"is-searchable":true,"func-delete":_vm.deleteZone,"func-delete-swal":_vm.cascadeConfirmDeleteOptions,"export-exclude-fields":[
                      'id',
                      'state.enabled' ]},on:{"mounted":function($event){_vm.table = $event},"updated":function($event){_vm.table = $event}},scopedSlots:_vm._u([{key:"overlay",fn:function(){return [_c('overlay-loading',{attrs:{"items":[
          { state: _vm.table.loading, desc: 'Loading Zones', loaded: _vm.table.items.length},
          { state: state.loading, desc: 'Rendering Template'} ]}})]},proxy:true},{key:"cell(name)",fn:function(ref){
        var data = ref.data;
return [_c('b-media',{attrs:{"vertical-align":"center","no-body":""}},[_c('b-media-aside',[(_vm.hasCustomAvatar(data.item.avatar))?_c('b-avatar',{directives:[{name:"b-modal",rawName:"v-b-modal.avatar-modal",modifiers:{"avatar-modal":true}}],attrs:{"variant":"primary","size":"2.5em","badge-variant":"white"}},[_c('b-img',{attrs:{"fluid":"","fluid-grow":"","src":data.item.avatar.src,"alt":data.item.avatar.name}})],1):_c('b-avatar',{directives:[{name:"b-modal",rawName:"v-b-modal.avatar-modal",modifiers:{"avatar-modal":true}}],attrs:{"variant":"primary","size":"2.5em","badge-variant":"white"}},[_c('font-awesome-icon',{attrs:{"icon":_vm.icon}})],1)],1),_c('b-media-body',{staticClass:"align-self-center"},[_c('b-link',{staticClass:"font-weight-bold d-block text-nowrap",attrs:{"to":{ name: 'management-zone', params: { id: data.item.id, slug: data.item.slug } }}},[_vm._v(" "+_vm._s(data.item.name)+" ")])],1)],1)]}},{key:"cell(counties)",fn:function(ref){
        var data = ref.data;
return [_c('div',{staticClass:"d-flex flex-wrap align-items-center"},_vm._l((data.item.counties),function(county){return _c('b-badge',{key:county,staticClass:"county-badge font-small-2 font-weight-normal",attrs:{"variant":"secondary"}},[_vm._v(_vm._s(county))])}),1)]}},{key:"cell(state.enabled)",fn:function(ref){
        var data = ref.data;
return [_c('b-badge',{staticClass:"text-capitalize",attrs:{"pill":"","variant":("light-" + (data.item.state.enabled ? 'success' : 'danger'))}},[_vm._v(" "+_vm._s(data.item.state.enabled)+" ")])]}},{key:"cell(row-options)",fn:function(ref){
        var data = ref.data;
return [_c('b-dropdown-item',{staticClass:"table-row-option-view",attrs:{"to":{ name: 'management-zone', params: { id: data.item.id, slug: data.item.slug } }}},[_c('feather-icon',{attrs:{"icon":"FileTextIcon"}}),_c('span',{staticClass:"align-middle ml-50"},[_vm._v("View")])],1),(_vm.$can('update', 'management-zones') || _vm.$can('delete', 'management-zones'))?[_c('b-dropdown-divider'),_c('can',{attrs:{"do":"update","on":"management-zones"}},[_c('b-dropdown-item',{staticClass:"table-row-option-enable",on:{"click":function($event){return _vm.updateZone(data.item)}}},[_c('font-awesome-icon',{attrs:{"icon":['fas', data.item.state.enabled ? 'toggle-off' : 'toggle-on']}}),_c('span',{staticClass:"align-middle ml-50"},[_vm._v(_vm._s(data.item.state.enabled ? 'Disable' : 'Enable'))])],1)],1),_c('can',{attrs:{"do":"delete","on":"management-zones"}},[_c('b-dropdown-item',{staticClass:"table-row-option-delete",on:{"click":function($event){return _vm.$refs.layout.confirmDelete(data.item, _vm.deleteZone, _vm.cascadeConfirmDeleteOptions)}}},[_c('feather-icon',{attrs:{"icon":"TrashIcon"}}),_c('span',{staticClass:"align-middle ml-50"},[_vm._v("Delete")])],1)],1)]:_vm._e()]}}],null,true)})]}},{key:"debug",fn:function(){return [_c('debug',{attrs:{"collapsed":true}},[_vm._v(_vm._s(_vm.table))])]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }