<template>
  <page-layout ref="layout">
    <template #actions="{ }">
      <can do="create" on="management-zones">
        <b-button v-b-tooltip="'Create'" v-b-modal="'zone-modal'" variant="transparent" size="sm">
          <font-awesome-icon icon="fa-solid fa-plus" />
        </b-button>
        <zone-modal id="zone-modal" @created="addZone" />
      </can>
    </template>

    <template #dropdown-options="{ }">
      <b-dropdown-item @click="refresh">
        <feather-icon icon="RotateCwIcon"/>
        <span class="align-middle ml-50">Refresh</span>
      </b-dropdown-item>
    </template>

    <template #content="{ state }">
      <table-layout ref="table-layout"
                    :items="table.items"
                    :fields="table.fields"
                    :sorting="table.sorting"
                    :loading="table.loading"
                    :subscriptions="table.subscriptions"
                    :is-searchable="true"
                    :func-delete="deleteZone"
                    :func-delete-swal="cascadeConfirmDeleteOptions"
                    :export-exclude-fields="[
                        'id',
                        'state.enabled',
                    ]"
                    @mounted="table = $event"
                    @updated="table = $event">

        <template #overlay>
          <overlay-loading :items="[
            { state: table.loading, desc: 'Loading Zones', loaded: table.items.length},
            { state: state.loading, desc: 'Rendering Template'},
          ]" />
        </template>

        <!-- Column: Name -->
        <template #cell(name)="{data}">
          <b-media vertical-align="center" no-body>
            <b-media-aside>
              <b-avatar v-if="hasCustomAvatar(data.item.avatar)" v-b-modal.avatar-modal variant="primary" size="2.5em" badge-variant="white">
                <b-img fluid fluid-grow :src="data.item.avatar.src" :alt="data.item.avatar.name"/>
              </b-avatar>
              <b-avatar v-else v-b-modal.avatar-modal variant="primary" size="2.5em" badge-variant="white">
                <font-awesome-icon :icon="icon"/>
              </b-avatar>
            </b-media-aside>
            <b-media-body class="align-self-center">
              <b-link :to="{ name: 'management-zone', params: { id: data.item.id, slug: data.item.slug } }" class="font-weight-bold d-block text-nowrap">
                {{ data.item.name }}
              </b-link>
            </b-media-body>
          </b-media>
        </template>

        <!-- Column: Counties -->
        <template #cell(counties)="{data}">
          <div class="d-flex flex-wrap align-items-center">
            <b-badge v-for="county in data.item.counties" :key="county" class="county-badge font-small-2 font-weight-normal" variant="secondary">{{ county }}</b-badge>
          </div>

        </template>

        <!-- Column: State - Enabled -->
        <template #cell(state.enabled)="{data}">
          <b-badge pill :variant="`light-${data.item.state.enabled ? 'success' : 'danger'}`" class="text-capitalize">
            {{ data.item.state.enabled }}
          </b-badge>
        </template>

        <!-- Column: Actions -->
        <template #cell(row-options)="{data}">
          <b-dropdown-item :to="{ name: 'management-zone', params: { id: data.item.id, slug: data.item.slug } }"
                           class="table-row-option-view">
            <feather-icon icon="FileTextIcon" />
            <span class="align-middle ml-50">View</span>
          </b-dropdown-item>

          <template v-if="$can('update', 'management-zones') || $can('delete', 'management-zones')">
            <b-dropdown-divider/>
            <can do="update" on="management-zones">
              <b-dropdown-item class="table-row-option-enable"
                               @click="updateZone(data.item)">
                <font-awesome-icon :icon="['fas', data.item.state.enabled ? 'toggle-off' : 'toggle-on']"/>
                <span class="align-middle ml-50">{{ data.item.state.enabled ? 'Disable' : 'Enable' }}</span>
              </b-dropdown-item>
            </can>
            <can do="delete" on="management-zones">
              <b-dropdown-item class="table-row-option-delete"
                               @click="$refs.layout.confirmDelete(data.item, deleteZone, cascadeConfirmDeleteOptions)">
                <feather-icon icon="TrashIcon" />
                <span class="align-middle ml-50">Delete</span>
              </b-dropdown-item>
            </can>
          </template>

        </template>
      </table-layout>
    </template>

    <template #debug>
      <debug :collapsed="true">{{ table }}</debug>
    </template>
  </page-layout>
</template>

<script>
import PageLayout from '@/components/PageLayout.vue';
import TableLayout from '@/components/TableLayout.vue';
import OverlayLoading from '@/components/OverlayLoading.vue';
import ZoneModal from '@/views/management/zones/ZoneModal.vue'
import avatar from '@/mixins/avatar.mixin'
import notify from '@/mixins/notify.mixin';
import print from '@/mixins/print.mixin';
import { API, graphqlOperation } from 'aws-amplify';
import { listZones, updateZone, onCreateZone, onUpdateZone, onDeleteZone} from '@/graphql/queries/zones';
import { cascadeDeleteZone, cascadeConfirmDeleteOptions} from '@/graphql/cascade/zone';

export default {
  name: 'Zones',
  components: {
    OverlayLoading,
    PageLayout,
    TableLayout,
    ZoneModal
  },
  mixins: [ avatar, notify, print ],
  data() {
    return {
      table: {
        items: [],
        loading: true,
        fields: [
          {
            key: 'name',
            label: 'Name',
            sortable: true,
            filterable: true,
            visible: true,
          },
          {
            key: 'counties',
            label: 'Counties',
            sortable: false,
            filterable: true,
            visible: true,
          },
          {
            key: 'state.enabled',
            label: 'Enabled',
            sortable: true,
            filterable: true,
            visible: true,
            tdClass: 'align-middle'
          },
          {
            key: 'row-options',
            label: '',
            sortable: false,
            filterable: false,
            visible: true,
            tdClass: ['align-middle', 'table-row-options']
          },
        ],
        sorting: {
          by: 'name',
          desc: false
        },
        transition: {
          name: 'flip-list'
        },
        subscriptions: {
          onCreate: null,
          onUpdate: null,
          onDelete: null
        }
      },
      icon: 'fa-solid fa-map',
      debug: { },
      cascadeConfirmDeleteOptions,
    }
  },
  async created() {
    await this.listZones();
    this.onCreateZone();
    this.onUpdateZone();
    this.onDeleteZone();
  },
  mounted() {
    this.$refs.layout.state.loading = false
  },
  methods: {
    /** Table Data **/
    async listZones() {
      this.table.loading = true
      const response = await API.graphql(graphqlOperation(listZones));
      this.table.items = response.data.listZones.items;
      this.table.loading = false
    },
    async addZone(zone) {
      this.table.loading = true
      this.table.items.push(zone)
      this.table.loading = false
    },
    async updateZone(zone) {
      try {
        zone.state.enabled = !zone.state.enabled
        await API.graphql(graphqlOperation(updateZone, { input: { id: zone.id, state: zone.state } } ));
        this.notify({ title: 'Success', text: 'Zone was successfully updated', icon: this.icon, variant: 'success' });
      }
      catch (err) {
        console.error(err)
        this.notify({ title: 'Error', text: 'Zone failed to update', icon: this.icon, variant: 'danger'});
      }
    },
    async deleteZone(zone, swalCallback) {
      try {
        this.table.loading = true
        await this.cascadeDeleteZone(zone.id, swalCallback)
        this.table.items = this.table.items.filter(item => item.id !== zone.id);
        this.notify({ title: 'Success', text: 'Zone was successfully deleted', icon: this.icon, variant: 'success' });
      }
      catch(error) {
        console.error(error)
        this.notify({ title: 'Error', text: 'Zone failed to delete', icon: this.icon, variant: 'danger'});
        throw error //for Swal
      }
      finally {
        this.table.loading = false
      }
    },
    cascadeDeleteZone,

    /** Subscriptions **/
    onCreateZone() {
      this.table.subscriptions.onCreate = API.graphql(graphqlOperation(onCreateZone)).subscribe((sourceData) => {
        this.syncNotification()
        const zone = sourceData.value.data.onCreateZone
        if (zone && !this.table.items.map(item => item.id).includes(zone.id)) {
          this.table.loading = true
          this.table.items = [zone, ...this.table.items];
          this.table.loading = false
        }
      });
    },
    onUpdateZone() {
      this.table.subscriptions.onUpdate = API.graphql(graphqlOperation(onUpdateZone)).subscribe((sourceData) => {
        this.syncNotification()
        const zone = sourceData.value.data.onUpdateZone
        if (zone && this.table.items.map(item => item.id).includes(zone.id)) {
          this.table.loading = true
          const index = this.table.items.findIndex(item => item.id === zone.id)
          if(index > -1) {
            this.table.items.splice(index, 1, zone)
          }
          this.table.loading = false
        }
      });
    },
    onDeleteZone() {
      this.table.subscriptions.onDelete = API.graphql(graphqlOperation(onDeleteZone)).subscribe((sourceData) => {
        this.syncNotification()
        const zone = sourceData.value.data.onDeleteZone
        if(zone && this.table.items.map(item => item.id).includes(zone.id)) {
          this.table.loading = true
          this.table.items = this.table.items.filter(item => item.id !== zone.id);
          this.table.loading = false
        }
      });
    },

    /** Actions **/
    async refresh() {
      await this.listZones()
    },
  }
}
</script>

<style lang="scss">
  .county-badge {
    margin-right: .5em;
    margin-bottom: 0.25em
  }
</style>
